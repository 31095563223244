/*
 * @Author: Onein
 * @Date: 2019-04-22 18:25:25
 * @Last Modified by: Onein
 * @Last Modified time: 2020-07-30 17:17:12
 */

import { app as api } from '@ekuaibao/whispered'

// @i18n-ignore-all
export default [
  {
    id: '@serach-engine',
    path: '/serach-engine',
    ref: '/',
    onload: () => import('./search-engine-view'),
    onfirst: async app => {
      await app.store.dynamic('@searchEngine', () => import('./search-engine.store')).load('@searchEngine')
      api.invokeService('@common:get:bank:channels')
    }
  },
  {
    point: '@@layers',
    prefix: '@serach-engine',
    onload: () => import('./layers')
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'serach-engine',
        pId: '',
        weight: 0,
        label: '搜索',
        href: '/serach-engine',
        icon: 'menu-loan'
      }
    ]
  }
]
